import { Link } from "gatsby"
import React,{useEffect,useState} from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import noImage from "../../images/no-image.png"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./OffplanCardSlider.scss"
import _ from "lodash"
import GGFXImage from "../../modules/GGFXImage"
import { removeDataAttributes } from "../Common/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"


const OffplanCardSlider = ({ offplanData,propertyData,newlayout2 }) => {
  const imagename = "new-developments.images.tileimg"

  const {isMobile} = useDeviceMedia()

  const increasePercent=(price,percentage)=>{
    return price+(percentage/100)*price
  }

  const decreasePercent=(price,percentage)=>{
    return price-(percentage/100)*price
  }
  const minPrice=(offplanData?.price!==0&&offplanData?.price)?decreasePercent(offplanData?.price,10):"";
  const maxPrice=(offplanData?.price!==0&&offplanData?.price)?increasePercent(offplanData?.price,10):"";

  const filteredData1 = propertyData.filter(item => {
    const areaMatch = item.node?.area === offplanData?.area;
    const slugNotEqual = item.node?.slug !== offplanData?.slug;

    return areaMatch && slugNotEqual
    
  })

  const filteredData2 = propertyData.filter(item => {
    const developerMatch = item.node?.developer === offplanData?.developer;
    const slugNotEqual = item.node?.slug !== offplanData?.slug;

    return developerMatch && slugNotEqual 
  })


  const filteredData3 = propertyData.filter(item => {
    const priceMatch = (offplanData?.price !== 0 && offplanData?.price) 
      ? item.node?.price >= minPrice && item.node?.price <= maxPrice 
      : true;

    const slugNotEqual = item.node?.slug !== offplanData?.slug;

    
    return priceMatch && slugNotEqual 
  })

  const filteredData = [...filteredData1,...filteredData2,...filteredData3].slice(0, 4)
  const title = newlayout2 ? "More Off Plan Projects": " Other properties that may interest you"

  return (
    <div className={`offplan-newdev-slider ${newlayout2?'newlayout2':''}`}>
      <Container>
        <h2 className="title">{title}</h2>
        <CustomSlider className="offplan-prop-slider" slidecount={isMobile?1:4}>
          {filteredData?.map(({node}, index) => {
            const image =
              node?.images?.length > 0
                ? node?.images[0]
                : ""

                const newDev=removeDataAttributes(node)
            return (
              <ScrollAnimation
                className="offplan-slider-item"
                animateIn="animate__slideInUp"
                animateOnce
                delay={index * 100}
                offset={100}
              >
                <div className="image-section">
                  <Link
                    to={`/off-plan/properties/for-sale/in-dubai/${node?.slug}/${node?.crm_id}/`}
                  >
                    {image?.url ? (
                      <GGFXImage
                      ImageSrc={image}
                      altText={`${node?.banner_title}`}
                      imagetransforms={newDev?.ggfx_results}
                      renderer="srcSet"
                      imagename={imagename}
                      strapiID={node?.id}
                      classNames="property-card_image"
                    />
                    ) : (
                      <img src={noImage} alt="No Image" />
                    )}
                  </Link>
                  <div className="content-section">
                    <h4>{node?.banner_title}</h4>
                    {node?.developer && <p>by {node?.developer}</p>}
                  </div>
                </div>
              </ScrollAnimation>
            )
          })}
        </CustomSlider>
      </Container>
    </div>
  )
}

export default OffplanCardSlider
