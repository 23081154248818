import React, { useState } from "react"
import "./OffplanPropertyInfo.scss"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/offplan_floorplan.json"
import DefaultForm from "../forms/default-form-layout"
import Map from "../PropertyMap/Map"
import AccordianValuation from "../AccordianValuation/AccordianValuation"
import FormFields1 from "../../../static/forms/offplan_register_new_layout.json"
import ReadMoreContent from "../ReadMoreContent/ReadMoreContent"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanPropertyInfo = ({ offplanData }) => {
  const [show, setShow] = useState(false)
  const [floorPlanUrl, setFloorplanUrl] = useState("")

  const handleModal = data => {
    setShow(true)
    setFloorplanUrl(data)
  }

  const handleClose = () => {
    setShow(false)
  }

  const modules = offplanData?.offplan_modules

  const keyInfo = modules?.filter(
    item => item?.select_module === "property_info"
  )
  const keyInfoData = keyInfo?.length > 0 ? keyInfo[0]?.icon_list : ""

  const paymentModule = modules?.filter(
    item => item?.strapi_component === "page-modules.payment-info"
  )
  const paymentData =
    paymentModule?.length > 0 ? paymentModule[0]?.payment_plan : ""

  const amenitiesModule = modules?.filter(
    item => item?.select_module === "amenities"
  )
  const amenitiesData =
    amenitiesModule?.length > 0 ? amenitiesModule[0]?.icon_list : ""

  const faqmodule = modules?.filter(
    item => item?.strapi_component === "page-modules.accordion"
  )

  const faqData = faqmodule?.length > 0 ? faqmodule[0] : ""

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const propImage =
    offplanData?.images?.length > 0 ? offplanData?.images[0]?.url : ""
  const mainContent = modules?.filter(
    item => item?.strapi_component === "components.title-desc"
  )

  const mainContentText =
    mainContent?.length > 0
      ? mainContent[0]?.description?.data?.description
      : ""

  const secondaryContent = modules?.filter(
    item => item?.strapi_component === "page-modules.image-content"
  )

  const secondaryData =
    secondaryContent?.length > 0 ? secondaryContent[0]?.left_right_block : ""

  return (
    <>
      <div className="offplannew-property-wrapper">
        <div className="left-section-offplanprop">
          <h1>{offplanData?.title1}</h1>
          {offplanData?.developer && (
            <p className="dev-name">
              by <span>{offplanData.developer}</span>
            </p>
          )}

          {keyInfoData?.length > 0 && (
            <>
              <div className="hori-line"></div>
              <div className="ofplan_moreinfo-icon">
                <h2>Key Information</h2>
                <div className="about-property-items">
                  {keyInfoData?.map(item => {
                    return (
                      <div className="item-list-info">
                        <p className="info">{item?.heading}</p>

                        {item?.ctas?.link_type === "onclick" ? (
                          item?.heading === "Floor Plan" ? (
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                handleModal(item?.ctas?.custom_link)
                              }
                            >
                              {item?.ctas?.title}
                            </a>
                          ) : (
                            <a href={item?.ctas?.custom_link} target="_blank">
                              {item?.ctas?.title}
                            </a>
                          )
                        ) : (
                          item?.sub_content?.data?.sub_content && (
                            <div className="sub_info">
                              <ContentModule
                                Content={item?.sub_content?.data?.sub_content}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}

          {paymentData?.length > 0 && (
            <>
              <div className="horizontal-line"></div>
              <div className="payment-layout2-wrapper">
                <h2>Payment Plan</h2>
                <div className="payment-layout2-items">
                  {paymentData?.map(item => {
                    return (
                      <div className="layout2_lists-pay">
                        <p className="percent_value">{item?.payment_percent}</p>
                        <p className="percent_name">{item?.payment_name}</p>
                        <p className="percent_time">{item?.payment_time}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}

          {amenitiesData?.length > 0 && (
            <>
              <div className="horizontal-line"></div>
              <div className="amenities-details">
                <h2>Amenities</h2>
                <ul className="amenities-list-items">
                  {amenitiesData?.map(amenities => {
                    return (
                      <li
                        className="aminities-contents"
                        aria-label={amenities?.heading}
                      >
                        <i className="icon tick-icon"></i>
                        <p className="feature-list">{amenities?.heading}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </>
          )}

          {mainContentText && (
            <>
              <div className="horizontal-line"></div>
              <h2 className="h2-titles">About the Project</h2>
              <div className="content_lists">
                <div className="main-content">
                  <ContentModule Content={mainContentText} />
                </div>
                {secondaryData?.length > 0 && (
                  <div className="sub-content-section">
                    <ReadMoreContent items={secondaryData} />
                  </div>
                )}
              </div>
            </>
          )}

          {offplanData?.latitude && offplanData?.longitude ? (
            <>
              <div className="horizontal-line"></div>
              <div className="property-map-component new-layout-off">
                <h2 className="h2-titles">Location</h2>
                <p className="address_map_property">{offplanData?.title1}</p>
                <Map property_details_map_data={offplanData} />
              </div>
            </>
          ) : null}

          {faqData?.add?.length > 0 && (
            <>
              <div className="horizontal-line"></div>
              <AccordianValuation
                add_toggle={faqData?.add}
                title={faqData?.title}
                content={""}
                valuation={true}
                accordianPage="offplanlayout2-new-accordion"
              />
            </>
          )}
        </div>
        <div className="right-section-offplanprop" id='right-section-form'>
          <div className="offplan-layout2-form">
            <DefaultForm
              formTitle={"Register your interest"}
              fields={FormFields1}
              sourceUrl={pageurl}
              prop_address={offplanData?.title1}
              prop_img_url={propImage}
              to_email_id={
                offplanData?.additional_email
                  ? offplanData?.additional_email +
                    ", hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com"
                  : "hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com"
              }
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe offplan-brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                sourceUrl={pageurl}
                phone_id_value={"floorplan_download-button"}
                fields={FormFields}
                formTitle="Download Floorplan"
                prop_address={offplanData?.banner_title}
                brochureUrl={floorPlanUrl}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com"
                    : "hauswebsiteleads@gmail.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OffplanPropertyInfo